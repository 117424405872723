import dayjs from 'dayjs'

/**
 * Takes a string representation of a date and returns a string formatted
 * per AP guidelines. (March, April, May, June, July spelled in full,
 * three letter abbreviations for all others besides September => Sept)
 * @param String input
 * @returns String
 */
export function getAPformat (input) {
  const date = dayjs(input)
  // Months are 0 based in JS; 2 = March
  if ([2, 3, 4, 5, 6].includes(date.month())) {
    return date
      .format('MMMM D, YYYY')
  } else {
    return date
      .format('MMM. D, YYYY')
      .replace('Sep', 'Sept')
  }
}

export function getHash (title) {
  return '#' + title
    .toLowerCase()
    .trim()
    .replace(/[^-0-9a-zA-Z ]/g, '')
    .replace(/ /g, '-')
}

export function validateEmail (email) {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email)
}
