// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/webpack/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{box-sizing:border-box}.link_mcAlE{display:block;padding:12px 0;position:relative;color:#212325;font-family:\"BuckeyeSans\",Arial,\"Helvetica Neue\",Helvetica,sans-serif;font-size:1rem;font-weight:700;text-decoration:none}@media(max-width:767px){.link_mcAlE{padding:16px 0;width:100%}}.link_mcAlE:after{content:\"\";bottom:0;display:block;width:0;height:4px;background:#ba0c2f;position:absolute;transition:width .3s;margin:0 auto}.link_mcAlE.active_t8gNg:after{width:100%}@media(max-width:767px){.link_mcAlE.active_t8gNg:after{background:#ba0c2f;height:24px;left:-12px;position:absolute;top:50%;transform:translateY(-50%);transition:height .3s;width:4px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_mcAlE",
	"active": "active_t8gNg"
};
module.exports = ___CSS_LOADER_EXPORT___;
